import { Grid, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import supabase from "./client";
import UseMediaQuery from "./useMediaQuery";
import moment from "moment";

export default function Event() {
    const { id } = useParams();
    const [event, setEvent] = useState([]);
    const matches = UseMediaQuery("(min-width:768px)");
    // console.log("matches: ", matches);

    useEffect(async () => {
        let { data: events, error } = await supabase
            .from("events")
            .select("*")
            .eq("id", id);
        setEvent(events[0]);
    }, []);

    function NewlineText(props) {
        const text = props.text;
        const newText = text.split("\n").map((str) => <p>{str}</p>);
        return newText;
    }

    if (!event) {
        return <h2>Sorry, event not found!</h2>;
    }

    // console.log("event: ", event);

    return (
        <div className="singleEvent">
            <Link id="back" to="/events">
                <p>back </p>
            </Link>

            <Grid container spacing={10}>
                {matches && (
                    <>
                        {" "}
                        <Grid item xs={12} md={6}>
                            <h1 id="eventName">{event.name}</h1>
                            <div className="eventDescription">
                                <p>{event.description}</p>
                            </div>
                            <Grid container spacing={3}>
                                {event.lineup && (
                                    <Grid item xs={6}>
                                        {event.lineup && (
                                            <div id="lineup">
                                                <h4>lineup</h4>
                                                {event.lineup.map((artist) => (
                                                    <p>{artist}</p>
                                                ))}
                                            </div>
                                        )}
                                    </Grid>
                                )}
                                {event.date && (
                                    <Grid item xs={6}>
                                        <div id="singleEventDate">
                                            <h4>date</h4>
                                            <p>
                                                {" "}
                                                {new moment(event.date).format(
                                                    "dddd"
                                                )}
                                                ,
                                                {" " +
                                                    new moment(
                                                        event.date
                                                    ).format("MMM Do")}
                                            </p>
                                            <p>{event.time}</p>
                                        </div>
                                    </Grid>
                                )}
                                {event.genre && (
                                    <Grid item xs={6}>
                                        {event.genre && (
                                            <div id="genre">
                                                <h4>genre</h4>
                                                {event.genre}
                                            </div>
                                        )}
                                    </Grid>
                                )}
                                {event.price && (
                                    <Grid item xs={6}>
                                        {event.price && (
                                            <div id="donation">
                                                <h4>donation</h4>
                                                <p>{event.price}</p>
                                            </div>
                                        )}
                                    </Grid>
                                )}
                                {(event.soundcloud || event.instagram) && (
                                    <Grid item xs={6}>
                                        <div id="socials">
                                            {(event.soundcloud && (
                                                <h4>socials</h4>
                                            )) ||
                                                (event.instagram && (
                                                    <h4>socials</h4>
                                                ))}
                                            {event.soundcloud && (
                                                <a
                                                    href={event.soundcloud}
                                                    target="_blank"
                                                >
                                                    <img
                                                        className="socialLogo"
                                                        src="https://bnrrozplueaxblukrrwk.supabase.in/storage/v1/object/sign/images/2000px-SoundCloud_-_Logo.svg.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJpbWFnZXMvMjAwMHB4LVNvdW5kQ2xvdWRfLV9Mb2dvLnN2Zy5wbmciLCJpYXQiOjE2NDI4NDQ0ODEsImV4cCI6MTk1ODIwNDQ4MX0.7zdsoG61OSlc7Zqa55y7Kg29NEYajhY4ebHtJEx631s"
                                                        width="40px"
                                                        alt="soundcloud"
                                                    ></img>
                                                </a>
                                            )}
                                            {event.instagram && (
                                                <a
                                                    href={event.instagram}
                                                    target="_blank"
                                                >
                                                    <img
                                                        className="socialLogo"
                                                        src="https://bnrrozplueaxblukrrwk.supabase.in/storage/v1/object/sign/images/Instagram_logo_2016.svg.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJpbWFnZXMvSW5zdGFncmFtX2xvZ29fMjAxNi5zdmcucG5nIiwiaWF0IjoxNjQyODQ1MTc0LCJleHAiOjE5NTgyMDUxNzR9.M5O1vTJDQVLO_b7HS5HRLzE54KZ6fFdS03zmFI-yHv0"
                                                        alt="instagram"
                                                        width="30px"
                                                    ></img>
                                                </a>
                                            )}
                                        </div>
                                    </Grid>
                                )}
                                {event.RSVP && (
                                    <Grid item xs={6}>
                                        <div id="RSVP">
                                            <h4>RSVP</h4>
                                            <a href={event.RSVP}>
                                                {event.RSVP.replace(
                                                    /^https?\:\/\//i,
                                                    ""
                                                )}
                                            </a>
                                        </div>
                                    </Grid>
                                )}
                            </Grid>
                            <div id="eventLinks"></div>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            display="flex"
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                        >
                            <img id="singleEventImage"
                                src={
                                    event.image ||
                                    "https://bnrrozplueaxblukrrwk.supabase.in/storage/v1/object/sign/images/KWIA_logo_icon_white.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJpbWFnZXMvS1dJQV9sb2dvX2ljb25fd2hpdGUucG5nIiwiaWF0IjoxNjQyNjk4MjA4LCJleHAiOjE5NTgwNTgyMDh9.UcJaBFrUrIGVsCx3qUVvsivA4qDYuW68RCM4c26mO3A"
                                }
                                width="70%"
                            ></img>
                        </Grid>
                    </>
                )}
                {!matches && (
                    <>
                        {" "}
                        <Grid
                            container
                            item
                            xs={12}
                            md={7}
                            display="flex"
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <img 
                                src={
                                    event.image ||
                                    "https://bnrrozplueaxblukrrwk.supabase.in/storage/v1/object/sign/images/KWIA_logo_icon_white.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJpbWFnZXMvS1dJQV9sb2dvX2ljb25fd2hpdGUucG5nIiwiaWF0IjoxNjQyNjk4MjA4LCJleHAiOjE5NTgwNTgyMDh9.UcJaBFrUrIGVsCx3qUVvsivA4qDYuW68RCM4c26mO3A"
                                }
                                width="80%"
                            ></img>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={5}
                            style={{ paddingTop: "40px" }}
                        >
                            <h2>{event.name}</h2>
                            <p>&nbsp;</p>
                            <div className="eventDescription">
                                <p>{event.description}</p>
                            </div>
                            <Grid container spacing={3}>
                                {event.lineup && (
                                    <Grid item xs={6}>
                                        <div id="lineup">
                                            <h4>lineup</h4>
                                            {event.lineup.map((artist) => (
                                                <p>{artist}</p>
                                            ))}
                                        </div>
                                    </Grid>
                                )}
                                {event.date && (
                                    <Grid item xs={6}>
                                        <div id="singleEventDate">
                                            <h4>date</h4>
                                            <p>
                                                {" "}
                                                {new moment(event.date).format(
                                                    "dddd"
                                                )}
                                                ,
                                                {" " +
                                                    new moment(
                                                        event.date
                                                    ).format("MMM Do")}
                                            </p>
                                            <p>{event.time}</p>
                                        </div>
                                    </Grid>
                                )}
                                {event.genre && (
                                    <Grid item xs={6}>
                                        <div id="genre">
                                            <h4>genre</h4>
                                            {event.genre}
                                        </div>
                                    </Grid>
                                )}
                                {event.price && (
                                    <Grid item xs={6}>
                                        <div id="donation">
                                            <h4>donation</h4>
                                            <p>{event.price}</p>
                                        </div>
                                    </Grid>
                                )}

                                {(event.soundcloud || event.instagram) && (
                                    <Grid item xs={6}>
                                        <div id="socials">
                                            {(event.soundcloud && (
                                                <h4>socials</h4>
                                            )) ||
                                                (event.instagram && (
                                                    <h4>socials</h4>
                                                ))}
                                            {event.soundcloud && (
                                                <a
                                                    href={event.soundcloud}
                                                    target="_blank"
                                                >
                                                    <img
                                                        src="https://bnrrozplueaxblukrrwk.supabase.in/storage/v1/object/sign/images/2000px-SoundCloud_-_Logo.svg.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJpbWFnZXMvMjAwMHB4LVNvdW5kQ2xvdWRfLV9Mb2dvLnN2Zy5wbmciLCJpYXQiOjE2NDI4NDQ0ODEsImV4cCI6MTk1ODIwNDQ4MX0.7zdsoG61OSlc7Zqa55y7Kg29NEYajhY4ebHtJEx631s"
                                                        width="40px"
                                                        alt="soundcloud"
                                                        className="socialLogo"
                                                    ></img>
                                                </a>
                                            )}
                                            {event.instagram && (
                                                <a
                                                    href={event.instagram}
                                                    target="_blank"
                                                >
                                                    <img
                                                        src="https://bnrrozplueaxblukrrwk.supabase.in/storage/v1/object/sign/images/Instagram_logo_2016.svg.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJpbWFnZXMvSW5zdGFncmFtX2xvZ29fMjAxNi5zdmcucG5nIiwiaWF0IjoxNjQyODQ1MTc0LCJleHAiOjE5NTgyMDUxNzR9.M5O1vTJDQVLO_b7HS5HRLzE54KZ6fFdS03zmFI-yHv0"
                                                        alt="instagram"
                                                        width="30px"
                                                        className="socialLogo"
                                                    ></img>
                                                </a>
                                            )}
                                        </div>
                                    </Grid>
                                )}

                                {event.RSVP && (
                                    <Grid item xs={6}>
                                        <div id="RSVP">
                                            <h4>RSVP</h4>
                                            <a href={event.RSVP}>
                                                {event.RSVP}
                                            </a>
                                        </div>
                                    </Grid>
                                )}
                            </Grid>
                            <div id="eventLinks"></div>
                        </Grid>
                    </>
                )}
            </Grid>
        </div>
    );
}

// const events = {
//     1: {
//         name: "Tabloid Press",
//         image: "https://bnrrozplueaxblukrrwk.supabase.in/storage/v1/object/sign/images/KWIA_logo_icon_white.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJpbWFnZXMvS1dJQV9sb2dvX2ljb25fd2hpdGUucG5nIiwiaWF0IjoxNjQyNjE5NTg2LCJleHAiOjE5NTc5Nzk1ODZ9.Z5wWoEaVf4IgCjSwUVBM0I3zPxRVdywVn6LA8J5Jxcs",
//     },
// };
