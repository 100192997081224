import { Link } from "react-router-dom";

export default function LandingPage() {
    return (
        <div id="landing-container">
            <div id="logoContainer">
                <img id="logo" src="./finallogo.png" width="80%"></img>
            </div>

            <p id="description">
                Ambient focused listening bar
                <br />
                Connect with sound, light and art in a queer space for modern
                rituals
            </p>

            <div id="aboutLinks">
                <p>
                    <Link to="/events">events </Link>

                    <Link to="/contact">contact</Link>
                </p>
            </div>
        </div>
    );
}
