import { Link } from "react-router-dom";


export default function About() {
    return (
        <>
            <p>
                {" "}
                this is the about page this is the about page this is the about
                page this is the about page this is the about page this is the
                about page  this is the about page
                <Link id="back" to="/">
                    back
                </Link>
            </p>
        </>
    );
}
