import axios from "./axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import supabase from "./client";
import { useMediaQuery } from "@mui/material";

export default function Events() {
    const [events, setEvents] = useState("");
    const [view, setView] = useState("This Week");
    const [thisMonthList, setThisMonthList] = useState(true);
    const [pastList, setPastList] = useState(false);

    useEffect(async () => {
        let { data: events, error } = await supabase.from("events").select("*");

        setEvents(
            events.sort(
                (a, b) =>
                    moment(a.date).format("YYYYMMDD") -
                    moment(b.date).format("YYYYMMDD")
            )
        );
    }, []);

    const matches = useMediaQuery("(min-width:600px)");

    if (!events) {
        return null;
    }

    const checkForHeader = () => {
        // console.log("matches: ", matches);
        if (!matches) return "smallHeader";
        return;
    };

    return (
        <div id="eventsContainer">
            <div id="eventHeadliner">
                <h3
                    style={
                        (view === "This Week" && {
                            backgroundColor: "rgba(245, 245, 245, 0.399)",
                        }) || { backgroundColor: "" }
                    }
                    className={checkForHeader()}
                    onClick={() => {
                        // console.log("view: ", view);
                        setView("This Week");
                    }}
                >
                    This Week
                </h3>
                <h3
                    style={
                        (view === "This Month" && {
                            backgroundColor: "rgba(245, 245, 245, 0.399)",
                        }) || { backgroundColor: "" }
                    }
                    className={checkForHeader()}
                    onClick={() => setView("This Month")}
                >
                    Upcoming
                </h3>
                <h3
                    style={
                        (view === "Past" && {
                            backgroundColor: "rgba(245, 245, 245, 0.399)",
                        }) || { backgroundColor: "" }
                    }
                    className={checkForHeader()}
                    onClick={() => setView("Past")}
                >
                    Past
                </h3>
            </div>
            {view === "This Month" && (
                <div id="thisMonthView">
                    <img
                        src="https://bnrrozplueaxblukrrwk.supabase.in/storage/v1/object/sign/images/list-512.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJpbWFnZXMvbGlzdC01MTIucG5nIiwiaWF0IjoxNjQzNjQ1OTExLCJleHAiOjE5NTkwMDU5MTF9.jU8d411kbS8no6cOBSBNQOxHP6Dzlw_TKeSmCMfTnTw"
                        width="25px"
                        height="25px"
                        alt="list view"
                        onClick={() => setThisMonthList(true)}
                    />
                    <img
                        src="https://bnrrozplueaxblukrrwk.supabase.in/storage/v1/object/sign/images/grid-three-up-128.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJpbWFnZXMvZ3JpZC10aHJlZS11cC0xMjgucG5nIiwiaWF0IjoxNjQzNjQ1ODY3LCJleHAiOjE5NTkwMDU4Njd9.dQXzMK-uoqOAFaoDyalQoG_Fk3CFN98PHKJEBB7Ku0Q"
                        onClick={() => setThisMonthList(false)}
                        width="25px"
                        height="25px"
                        alt="grid view"
                    ></img>
                </div>
            )}
            {view === "Past" && (
                <div id="thisMonthView">
                    <img
                        src="https://bnrrozplueaxblukrrwk.supabase.in/storage/v1/object/sign/images/list-512.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJpbWFnZXMvbGlzdC01MTIucG5nIiwiaWF0IjoxNjQzNjQ1OTExLCJleHAiOjE5NTkwMDU5MTF9.jU8d411kbS8no6cOBSBNQOxHP6Dzlw_TKeSmCMfTnTw"
                        width="25px"
                        height="25px"
                        alt="list view"
                        onClick={() => setPastList(true)}
                    />
                    <img
                        src="https://bnrrozplueaxblukrrwk.supabase.in/storage/v1/object/sign/images/grid-three-up-128.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJpbWFnZXMvZ3JpZC10aHJlZS11cC0xMjgucG5nIiwiaWF0IjoxNjQzNjQ1ODY3LCJleHAiOjE5NTkwMDU4Njd9.dQXzMK-uoqOAFaoDyalQoG_Fk3CFN98PHKJEBB7Ku0Q"
                        onClick={() => setPastList(false)}
                        width="25px"
                        height="25px"
                        alt="grid view"
                    ></img>
                </div>
            )}
            <p></p>
            {view === "This Week" && (
                <div id="events">
                    <Grid
                        container
                        spacing={10}
                        // display={"flex"}
                        // flexWrap={"wrap"}
                        justifyContent={(matches && "flex-start") || "center"}
                    >
                        {events
                            .filter((event, id) => {
                                return (
                                    moment(event.date).isAfter(
                                        moment().startOf("week")
                                        // .subtract(1, "days")
                                    ) &&
                                    moment(event.date).isBefore(
                                        moment().endOf("week").add(1, "days")
                                    )
                                );
                            })
                            .map((event, id) => {
                                return (
                                    <Grid
                                        item
                                        display={"flex"}
                                        flexDirection="column"
                                        justifyContent="space-between"
                                        flex={"0 1 400px"}
                                        key={event.id}
                                    >
                                        <h3>{event.name}</h3>
                                        {/* <p> &nbsp;</p> */}
                                        <Link
                                            to={`/event/${event.id}`}
                                            style={{ margin: 0 }}
                                        >
                                            <img
                                                src={
                                                    event.image ||
                                                    "./KWIA_logo_icon_white.png"
                                                }
                                                width="100%"
                                                style={{ padding: "5px 0" }}
                                            />
                                        </Link>
                                        <div>
                                            <div className="eventsDate">
                                                <p>
                                                    {" "}
                                                    {new moment(
                                                        event.date
                                                    ).format("dddd")}
                                                    ,
                                                    {" " +
                                                        new moment(
                                                            event.date
                                                        ).format("MMM Do")}
                                                </p>{" "}
                                                <p>{event.time}</p>
                                            </div>
                                            {(event.genre && (
                                                <p>{event.genre}</p>
                                            )) || <p> &nbsp;</p>}
                                        </div>
                                    </Grid>
                                );
                                // console.log("event: ", event);
                            })}
                    </Grid>
                </div>
            )}
            <Link id="back" to="/">
                <p>back </p>
            </Link>
            {/* <button>change</button> */}
            {view === "This Month" && thisMonthList && (
                <div>
                    <div className="event">
                        {" "}
                        <p className="first">name</p>
                        <p className="first">genre</p>
                        <p className="date">date</p>
                    </div>
                    {events
                        .sort((a, b) => {
                            return new Date(b.day) - new Date(a.day);
                        })
                        .filter(({ date }) => {
                            // console.log('date: ', date)
                            // console.log('moment(date).format("M"): ', moment(date).format("M"))

                            // console.log('date: ', moment(date))
                            // console.log('moment(date): ', moment(date))
                            // console.log(' moment().endOf("week").add(1, "days"): ',  moment().endOf("week").add(1, "days"))
                            // console.log(
                            //     moment(date).isBefore(
                            //         moment().startOf("week")
                            //     )
                            // );
                            return moment(date).isAfter(
                                moment().startOf("day").add(-1, "days")
                            );
                            // || moment(date).format("M") === moment().format("M")
                        })
                        .map((event, index) => {
                            // console.log(event);
                            return (
                                <div
                                    // className="event"
                                    id={`question${index}`}
                                    key={event.id}
                                >
                                    <a
                                        href={`#question${index}`}
                                        className="event"
                                    >
                                        <p className="first">{event.name}</p>

                                        <p className="first">{event.genre}</p>

                                        <p className="date">
                                            {" "}
                                            {moment(event.date).format(
                                                "MMM DD"
                                            )}
                                        </p>
                                        <i className="icon ion-md-arrow-forward date"></i>
                                        <i className="icon ion-md-arrow-down date"></i>
                                    </a>
                                    <div className="answer">
                                        <p> {event.description}</p>
                                        <p>&nbsp;</p>
                                        <Link
                                            style={{
                                                cursor: "pointer",
                                                textDecoration: "underline",
                                            }}
                                            to={`/event/${event.id}`}
                                        >
                                            + info
                                        </Link>
                                        <p>&nbsp;</p>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            )}
            {view === "This Month" && !thisMonthList && (
                <div id="events">
                    <Grid
                        container
                        spacing={10}
                        // display={"flex"}
                        // flexWrap={"wrap"}
                        justifyContent={(matches && "flex-start") || "center"}
                    >
                        {events
                            .sort((a, b) => {
                                return new Date(b.day) - new Date(a.day);
                            })
                            .filter(({ date }) => {
                                // console.log('date: ', date)
                                // console.log('moment(date).format("M"): ', moment(date).format("M"))

                                // console.log('date: ', moment(date))
                                // console.log('moment(date): ', moment(date))
                                // console.log(' moment().endOf("week").add(1, "days"): ',  moment().endOf("week").add(1, "days"))
                                // console.log(
                                //     moment(date).isBefore(
                                //         moment().startOf("week")
                                //     )
                                // );
                                return moment(date).isAfter(
                                    moment().startOf("day").add(-1, "days")
                                );
                                // || moment(date).format("M") === moment().format("M")
                            })
                            .map((event, id) => {
                                return (
                                    <Grid
                                        item
                                        display={"flex"}
                                        flexDirection="column"
                                        justifyContent="space-between"
                                        flex={"0 1 400px"}
                                        key={event.id}
                                    >
                                        <h3>{event.name}</h3>
                                        {/* <p> &nbsp;</p> */}
                                        <Link
                                            to={`/event/${event.id}`}
                                            style={{ margin: 0 }}
                                        >
                                            <img
                                                src={
                                                    event.image ||
                                                    "./KWIA_logo_icon_white.png"
                                                }
                                                width="100%"
                                                style={{ padding: "5px 0" }}
                                            />
                                        </Link>
                                        <div>
                                            <p>
                                                {" "}
                                                {new moment(event.date).format(
                                                    "dddd"
                                                )}
                                                ,
                                                {" " +
                                                    new moment(
                                                        event.date
                                                    ).format("MMM Do")}
                                            </p>{" "}
                                            <p>{event.time}</p>
                                        </div>
                                    </Grid>
                                );
                                // console.log("event: ", event);
                            })}
                    </Grid>
                </div>
            )}

            {view === "Past" && pastList && (
                <div>
                    <div className="event">
                        {" "}
                        <p className="first">name</p>
                        <p className="first">genre</p>
                        <p className="date">date</p>
                    </div>
                    {events
                        // .sort((a, b) => {
                        //     return new Date(b.day) - new Date(a.day);
                        // })
                        .sort(
                            (a, b) =>
                                moment(b.date).format("YYYYMMDD") -
                                moment(a.date).format("YYYYMMDD")
                        )
                        .filter(({ date }) => {
                            // console.log('date: ', date)
                            // console.log('moment(date).format("M"): ', moment(date).format("M"))

                            // console.log('date: ', moment(date))
                            // console.log('moment(date): ', moment(date))
                            // console.log(' moment().endOf("week").add(1, "days"): ',  moment().endOf("week").add(1, "days"))
                            // console.log(
                            //     moment(date).isBefore(
                            //         moment().startOf("week")
                            //     )
                            // );
                            return moment(date).isBefore(
                                moment().startOf("day").add(-1, "days")
                            );
                            // || moment(date).format("M") === moment().format("M")
                        })
                        .map((event, index) => {
                            // console.log(event);
                            return (
                                <div
                                    // className="event"
                                    id={`question${index}`}
                                    key={event.id}
                                >
                                    <a
                                        href={`#question${index}`}
                                        className="event"
                                    >
                                        <p className="first">{event.name}</p>

                                        <p className="first">{event.genre}</p>

                                        <p className="date">
                                            {" "}
                                            {moment(event.date).format(
                                                "MMM DD"
                                            )}
                                        </p>
                                        <i className="icon ion-md-arrow-forward date"></i>
                                        <i className="icon ion-md-arrow-down date"></i>
                                    </a>
                                    <div className="answer">
                                        <p> {event.description}</p>
                                        <p>&nbsp;</p>
                                        <Link
                                            style={{
                                                cursor: "pointer",
                                                textDecoration: "underline",
                                            }}
                                            to={`/event/${event.id}`}
                                        >
                                            + info
                                        </Link>
                                        <p>&nbsp;</p>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            )}

            {view === "Past" && !pastList && (
                <div id="events">
                    <Grid
                        container
                        spacing={10}
                        // display={"flex"}
                        // flexWrap={"wrap"}
                        justifyContent={(matches && "flex-start") || "center"}
                    >
                        {events
                            .sort(
                                (a, b) =>
                                    moment(b.date).format("YYYYMMDD") -
                                    moment(a.date).format("YYYYMMDD")
                            )
                            .filter(({ date }) => {
                                // console.log('date: ', date)
                                // console.log('moment(date).format("M"): ', moment(date).format("M"))

                                // console.log('date: ', moment(date))
                                // console.log('moment(date): ', moment(date))
                                // console.log(' moment().endOf("week").add(1, "days"): ',  moment().endOf("week").add(1, "days"))
                                // console.log(
                                //     moment(date).isBefore(
                                //         moment().startOf("week")
                                //     )
                                // );
                                return moment(date).isBefore(
                                    moment().startOf("day").add(-1, "days")
                                );
                                // || moment(date).format("M") === moment().format("M")
                            })
                            .map((event, id) => {
                                return (
                                    <Grid
                                        item
                                        display={"flex"}
                                        flexDirection="column"
                                        justifyContent="space-between"
                                        flex={"0 1 400px"}
                                        key={event.id}
                                    >
                                        <h3>{event.name}</h3>
                                        {/* <p> &nbsp;</p> */}
                                        <Link
                                            to={`/event/${event.id}`}
                                            style={{ margin: 0 }}
                                        >
                                            <img
                                                src={
                                                    event.image ||
                                                    "./KWIA_logo_icon_white.png"
                                                }
                                                width="100%"
                                                style={{ padding: "5px 0" }}
                                            />
                                        </Link>
                                        <div>
                                            <p>
                                                {" "}
                                                {new moment(event.date).format(
                                                    "dddd"
                                                )}
                                                ,
                                                {" " +
                                                    new moment(
                                                        event.date
                                                    ).format("MMM Do")}
                                            </p>{" "}
                                            <p>{event.time}</p>
                                        </div>
                                    </Grid>
                                );
                                // console.log("event: ", event);
                            })}
                    </Grid>
                </div>
            )}
        </div>
    );
}
