import axios from "./axios";
import { useEffect, useState } from "react";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import About from "./about";
import Contact from "./contact";
import Events from "./events";
import LandingPage from "./landingpage";
import "./App.css";
import Event from "./event";
import Impressum from "./impressum";



export default function App() {
    const [open, setOpen] = useState(true);
    

    return (
        <BrowserRouter>
            <div id="app-container">
                <Routes>
                    <Route exact path="/" element={<LandingPage />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/events" element={<Events />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/event/:id" element={<Event/>}></Route>
                    <Route path="/impressum" element={<Impressum/>}></Route>
                </Routes>
                
            </div>
        </BrowserRouter>
    );
}
