import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

export default function Contact() {
    return (
        <>
            <div id='landing-container'>
               
                <h3>Contact</h3>
                For inquiries and bookings reach us at info@kwia.berlin
                <br />
                <br />
                <h3>Location</h3>
                Maybachufer 16, 12047, Berlin
                <br />
                <br />
                &nbsp;

                {/* <ReactPlayer url="https://bnrrozplueaxblukrrwk.supabase.in/storage/v1/object/sign/images/Transparent.mp4?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJpbWFnZXMvVHJhbnNwYXJlbnQubXA0IiwiaWF0IjoxNjQzNzU4NDY1LCJleHAiOjE5NTkxMTg0NjV9.1ILpb7DKm0opeLTrIWLgoLkZ7YK7IkNb_oAYNjui07k" loop="true" playing="true"></ReactPlayer> */}
                <Link to="/impressum">impressum</Link>
                <Link id="back" to="/">
                    back
                </Link>
            </div>
        </>
    ); 
}
