import React from "react";
import { Link } from "react-router-dom";

function Impressum() {
    return (
        <div id="impressum">
            <h3>impressum</h3>
            &nbsp;
            <Link id="back" to="/contact">
                <p>back </p>
            </Link>
            <p>
                kwia GmbH <br />
                Maybachufer 16 <br />
                12047 Berlin
                <br />
                <br />
                Eintragung im Handelsregister. <br />
                Registergericht: Berlin <br />
                Registernummer: HRB 216664 B <br />
                <br />
                Umsatzsteuer-Identifikationsnummer gemäß §27a
                <br />
                Umsatzsteuergesetz: DE331439561 <br />
                Amtsgericht Charlottenburg <br />
                <br />
                <b>Datenschutz</b>
                <br />
                Die Nutzung unserer Webseite ist in der Regel ohne Angabe
                personenbezogener Daten möglich. Soweit auf unseren Seiten
                personenbezogene Daten (beispielsweise Name, Anschrift oder
                eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich,
                stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
                ausdrückliche Zustimmung nicht an Dritte weitergegeben.
            </p>
        </div>
    );
}

export default Impressum;
